
@import (reference) '~@energiency/ui/src/style/colors';
.ErrorPage {
    background: linear-gradient(
        154.39deg,
        #006dbc 18.67%,
        rgba(0, 158, 227, 0.36) 87.12%,
        rgba(0, 159, 227, 0.015625) 87.12%,
        rgba(0, 159, 227, 0.36) 87.13%
    );
    .logo {
        max-width: 250px;
    }
    .bar {
        width: 80px;
        height: 4px;
    }
    .cloud {
        position: relative;
        font-size: 11vw;
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../../../assets/images/cloud.svg');
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
        }
    }
    a {
        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}
