@import '../fonts/avenir/avenir.css';
@import '../fonts/inconsolata/inconsolata.css';
@import '../fonts/arimo/arimo.css';
@import '../fonts/montserrat/montserrat.css';
@import '../fonts/opensans/opensans.css';
@import '../fonts/roboto/roboto.css';
@import '../fonts/sourcesanspro/sourcesanspro.css';


@import '~style/colors.less';
@import '~style/common.less';
@import '~style/flex.less';
@import './vue.style.less';

/**
 * App-wide Styles
 */

html {
    height: 100%;
    font-size: 100%;
}

body {
    font-family: 'Avenir LT Std', sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: var(--default-bg-color);
    color: var(--default-text-color);
    vertical-align: baseline;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
}

.viewport {
    min-height: 90vh;
}

/*.viewport-width-with-menu {
    width: calc(~"100vw - 280px");
}
.viewport-width-without-menu {
    width: calc(~"100vw");
}*/

.dropdown-menu {
    border-radius: 0;
}

.drop-element.ui-drop,
.UiPopover {
    z-index: 1051;
}

.input-group {
    border-radius: 0 !important;

    .input-group-addon {
        border-radius: 0 !important;
    }
    input {
        border-radius: 0 !important;
    }
}

.popover {
    border-radius: 0;
    max-width: 276px;
}

a,
a:hover {
    color: var(--primary-color);
}

div,
article {
    &.panel,
    &.alert {
        border-radius: 0 !important;
    }
}

.form-control {
    border-radius: 0px;
    color: var(--default-text-color);
}

.fixed {
    position: fixed;
}

.mouse-pointer {
    cursor: default !important;
}

.box {
    border: 1px solid #d9d9d9 !important;
}

.box-shadow {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border-bottom-color: #cccccc;
}

.dropdown-menu {
    border-radius: 0;

    > li > a:hover {
        background-color: var(--grey-color-light);

        &:hover {
            color: var(--primary-color);
        }
    }
}

.modal-content {
    border-radius: 0;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-border {
    border: 0 !important;
}

.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.vertical-divider {
    height: 100%;
    width: 1px;
    margin: 0 9px;
    overflow: hidden;
    background-color: #e5e5e5;
}

.filter {
    font-size: 1em;
    color: white;
    padding: 3px;
}

.round:not(.UiButton, .UiIconButton) {
    border-radius: 30px !important;
    height: 40px;
    min-width: 40px;
}

.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
    -ms-user-select: none;
    user-select: none;

    &::selection {
        background: transparent;
        color: inherit;
    }
}

iframe#launcher {
    display: none;
}

// This is a fix for a small bug in Angular Bootstrap v0.13.4 (a black background appears
// when closing a modal). It's been fixed in last releases but there are breaking changes
// (renamed directives or services).
.modal-backdrop {
    opacity: 0.5 !important;
}

@tooltip-bg: var(--grey-color-light);

/** Tooltips **/
.tooltip {
    > .tooltip-inner {
        background-color: var(--grey-color-light);
        color: var(--default-text-color);
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
    }

    &.top .tooltip-arrow {
        border-top-color: var(--grey-color-light);
    }
    &.right .tooltip-arrow {
        border-right-color: var(--grey-color-light);
    }
    &.left .tooltip-arrow {
        border-left-color: var(--grey-color-light);
    }
    &.bottom .tooltip-arrow {
        border-bottom-color: var(--grey-color-light);
    }
}

.toast-top-right {
    top: 72px;
}

@media print and (color) {
    * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

// custom css rules for pdf printing (elements hiding, size rules and colors)
@media print {
    body {
        width: 100%;
        overflow-y: visible;
    }

    #toolbar {
        display: none;
    }

    #printSection {
        visibility: visible !important;
    }

    #printSection * {
        visibility: visible !important;
    }

    report {
        display: block;
        position: relative;
        padding: 0 !important;
        width: 100% !important;

        .panel-default {
            .panel-heading {
                background: var(--grey-color-light) !important;
                -webkit-print-color-adjust: exact !important;
            }
        }
    }

    .left-menu-container {
        display: none !important;
    }

    .page-break {
        page-break-after: always !important;
    }

    .main.content {
        margin: 0px !important;
    }
}

label.required:after {
    content: ' *';
}

.serif {
    font-family: 'Times New Roman', Times, serif;
}

/* Gap classes */

.gap0 {
    gap: 0;
}

.gap1 {
    gap: var(--spacing-extra-small);
}

.gap2 {
    gap: var(--spacing-small);
}

.gap3 {
    gap: var(--spacing-medium);
}

.gap4 {
    gap: var(--spacing-large);
}

.gap5 {
    gap: var(--spacing-extra-large);
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}