
@import (reference) '~style/sidebar.less';
@import (reference) '~style/colors.less';
@import (reference) '~style/size.less';

.SidebarItem {
    position: relative;
    padding: 12px;
    overflow: hidden;
    white-space: nowrap;
    color: var(--grey-color-dark);
    border-radius: 0.375rem;
    text-decoration: none;
    flex-shrink: 0;
    transition: background 0.3s;
    .item-label {
        font-size: 1rem;
        line-height: 1;
        margin-top: 1px;
    }

    &:hover {
        cursor: pointer;
        background-color: @sidebar-bg-item-hover;
    }

    &.active {
        background-color: @sidebar-bg-item-active;
        .UiIcon {
            &.en-fw:before {
                width: 1em !important;
            }
        }
    }

    /deep/ .UiIcon {
        width: 20px !important;
        height: 20px;
        flex-shrink: 0;
        .svg-inline--fa {
            height: 20px;
        }
    }
}
