
@import (reference) '~style/navbar.less';
@import (reference) '~style/size.less';
@import (reference) '~style/colors.less';

.UiDropdownMenu {
    margin-top: 1.3em;
}

.UiButton.menu:not(.hover) {
    span {
        font-weight: 800;
        font-size: 0.85em;
    }
}

.divider {
    margin: 0;
}

.UiButton.actions:hover:not(.active) {
    color: var(--primary-color);
    .UiIcon {
        color: var(--primary-color);
    }
}
