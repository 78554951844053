
@import (reference) '~style/sidebar.less';
@import (reference) '~style/size.less';

.Sidebar {
    background-color: @sidebar-bg-color;
    transition: width 0.3s;
    width: @sidebar-collapsed-width;
    --fa-fw-width: 20px;
    flex-shrink: 0;

    &.collapsed {
        width: @sidebar-collapsed-width;
    }
    @media (--breakpoint-large) {
        width: @sidebar-uncollapsed-width;
    }

    .footer {
        min-height: 38px;
        display: flex;
        .About {
            display: none;
            @media (--breakpoint-large) {
                display: flex;
            }
        }

        /deep/ .UiButton.collapse-button {
            display: none;
            color: var(--grey-color-dark);
            background-color: @sidebar-bg-collapse-button;
            transition: background 0.3s;
            @media (--breakpoint-large) {
                display: block;
            }

            &:active,
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:hover {
                background-color: @sidebar-bg-item-hover;
            }
        }
    }

    // custom css rules for pdf printing (elements hiding, size rules and colors)
    @media print {
        display: none;
    }
}
