@font-face {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 400;
    src:
        url('AvenirLTStd-Light.woff2') format('woff2'),
        url('AvenirLTStd-Light.woff') format('woff');
}

@font-face {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 600;
    src:
        url('AvenirLTStd-Medium.woff2') format('woff2'),
        url('AvenirLTStd-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 700;
    src:
        url('AvenirLTStd-Heavy.woff2') format('woff2'),
        url('AvenirLTStd-Heavy.woff') format('woff');
}

@font-face {
    font-family: 'Avenir LT Std';
    font-style: oblique;
    font-weight: 400;
    src:
        url('AvenirLTStd-LightOblique.woff2') format('woff2'),
        url('AvenirLTStd-LightOblique.woff') format('woff');
}
