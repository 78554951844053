
@import (reference) '~style/navbar.less';
@import (reference) '~style/size.less';
@import (reference) '~style/colors.less';

.Navbar {
    min-height: @navbar-height;
    background-color: @navbar-bg-color;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    .logo {
        margin-left: 1.5em;
    }
    img {
        max-width: 180px;
        max-height: 40px;
    }
    .actions {
        margin-right: 0.9em;
    }

    // custom css rules for pdf printing (elements hiding, size rules and colors)
    @media print {
        display: none;
    }
}
