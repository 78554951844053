@import (reference) './colors.less';

.pointer {
    cursor: pointer;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    // Bootstrap override to make disabled buttons "more disabled"
    opacity: 0.35 !important;
}

.btn:active,
.btn:focus,
.btn:active:focus,
.btn.active {
    // Let's get rid of some Bootstrap annoying borders, shadows, etc.
    outline-style: none !important;
}

.perimeter-chevron-right {
    font-size: 14px;
    padding: 0 10px 0 15px;
    color: var(--default-text-color);
    margin-right: 5px;
}

kbd {
    background-color: #ececec;
    margin: 0px 5px 0px 5px;
    padding: 1px 3px 0;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
}

@import (reference) '~@energiency/ui/src/style/colors.less';

@font-size-base: 14px;
@font-size-large: ceil((@font-size-base * 1.25)); // ~18px
@font-size-small: ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1: floor((@font-size-base * 2.6)); // ~36px
@font-size-h2: floor((@font-size-base * 2.15)); // ~30px
@font-size-h3: ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4: ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5: @font-size-base;
@font-size-h6: ceil((@font-size-base * 0.85)); // ~12px
@line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed: floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family: inherit;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: inherit;

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-size: @font-size-base;
    line-height: @line-height-base;
}
// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @headings-font-family;
    font-weight: @headings-font-weight;
    line-height: @headings-line-height;
    color: @headings-color;

    small,
    .small {
        font-weight: 400;
        line-height: 1;
    }
}

h1,
h2,
h3 {
    margin-top: @line-height-computed;
    margin-bottom: (@line-height-computed / 2);

    small,
    .small {
        font-size: 65%;
    }
}
h4,
h5,
h6 {
    margin-top: (@line-height-computed / 2);
    margin-bottom: (@line-height-computed / 2);

    small,
    .small {
        font-size: 75%;
    }
}

h1 {
    font-size: @font-size-h1;
}
h2 {
    font-size: @font-size-h2;
}
h3 {
    font-size: @font-size-h3;
}
h4 {
    font-size: @font-size-h4;
}
h5 {
    font-size: @font-size-h5;
}
h6 {
    font-size: @font-size-h6;
}

img {
    vertical-align: middle;
}

p {
    margin: 0 0 (@line-height-computed / 2);
}

// Custom
.form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 0;
}
