
@import (reference) '~style/colors.less';

.About {
    white-space: nowrap;
    .version {
        font-weight: 700;
        color: var(--grey-color-dark);
        cursor: pointer;
    }
}
