/**
 * Legacy class.
 *
 * Use the Tachyons class instead, so add the `flex` class.
 */
.flex-row {
    display: flex;
    -webkit-flex-direction: row; /* Safari 6.1+ */
    flex-direction: row !important;
}

/**
 * Legacy class.
 *
 * @deprecated Use the Tachyons classes instead: `items-end ml-auto`.
 */
.flex-align-end {
    align-items: flex-end;
    margin-left: auto;
}

.flex-fill {
    flex: 1 0 0% !important;
}

.flex-shrink {
    flex-shrink: 1;
}
