/* Source Sans Pro */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(./SourceSansPro-Italic.ttf) format('truetype');
}
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./SourceSansPro-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./SourceSansPro-Bold.ttf) format('truetype');
}
